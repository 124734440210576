import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import DashboardLayoutView from '@/views/layout/DashboardLayoutView.vue'
import ApplicantListview from '@/views/applicants/ListView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component : DashboardLayoutView,
    children : [
      {
        path : 'applicants',
        name : 'ApplicantsList',
        component : ApplicantListview
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !localStorage.getItem('authenticated')) {
    next({ name: 'Login' })
  } 
  else{
    next()
  }
});

export default router
