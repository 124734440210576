<template>
    <section>
        <div class="pa-5">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field outlined
                                dense 
                                hide-details
                                label="Search Applicant Email" 
                                append-icon="mdi-magnify"
                                v-model="email"
                                @keyup.enter="searchApplicant()"
                                @click:append="searchApplicant()"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="text-right">
                            <v-btn color="primary" @click="applicant_dialog = true">add applicant</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-data-table
                    :headers="table_headers"
                    :items="visits"
                    class="elevation-1 table-sm"
                    :search="search"
                    >
                    <template v-slot:item="{item}">
                        <tr>
                            <td>{{ item.id }}</td>
                            <td>{{ item.event_id }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.contact_no }}</td>
                            <td>{{ item.municipality }}</td>
                            <td>{{ item.applying_for }}</td>
                            <td>
                                <v-btn color="primary" x-small v-if="item.went == 0" @click="confirmAttendance(item)">Confirm</v-btn>
                                <div v-if="item.went == 1">
                                    <div>{{ item.date_attended }}</div>
                                    <div>Confirmed By : {{ item.confirmed_by }}</div>
                                </div>
                            </td>
                            <td>
                                <v-btn color="primary" x-small @click="getApplications(item)">Applications</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </div>
        
        <v-dialog width="600" v-model="applicant_dialog">
            <v-stepper v-model="applicant_stepper">
                    <v-stepper-header>
                    <v-stepper-step
                        :complete="applicant_stepper > 1"
                        step="1"
                    >
                        Search Email
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="applicant_stepper > 2"
                        step="2"
                    >
                        Applicant Details
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        Select Event
                    </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-text-field label="Email Address" v-model="email" outlined></v-text-field>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="default" 
                                    @click="applicant_dialog = false">
                                    close
                                </v-btn>
                                &nbsp;
                                <v-btn
                                    color="primary"
                                    @click="getUserByEmail();"
                                    >
                                    Continue
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card>
                            <v-alert color="success" class="white--text" v-if="applicant.status == 'Active'">This Applicant is Active</v-alert>
                            <v-alert color="error" class="white--text" v-if="applicant.status != 'Active'">This Applicant is Not Yet Active</v-alert>

                            <v-row class="mt-5">
                                <v-col>
                                    <v-text-field dense label="First Name" 
                                        v-model="applicant.firstname" 
                                        outlined
                                        hide-details></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field dense label="Last Name" 
                                        v-model="applicant.lastname" 
                                        outlined
                                        hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field dense label="Contact Number" 
                                        v-model="applicant.contact_no" 
                                        outlined
                                        hide-details></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field dense label="Email Address" 
                                        v-model="applicant.email" 
                                        outlined
                                        hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select dense outlined hide-details label="Gender" 
                                        v-model="applicant.sex" :items="['Male', 'Female']"></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select dense outlined hide-details label="Educational Attainment" 
                                        v-model="applicant.highest_educational_level" :items="educational_attainments"></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field dense outlined hide-details label="Barangay" 
                                        v-model="applicant.barangay"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-combobox
                                        v-model="applicant.municipality"
                                        :items="cities"
                                        label="City"
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                                <v-col>
                                    <v-combobox
                                        v-model="applicant.province"
                                        :items="provinces"
                                        label="Province"
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-text-field label="Applying For" v-model="applicant.applying_for" dense outlined hide-details></v-text-field>

                            <div v-if="applicant.status != 'Active'">
                                <v-row>
                                    <v-col>
                                        <v-checkbox
                                            v-model="applicant.activate_account"
                                            label="Activate Account"
                                            true-value="1"
                                            false-value="0"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                            v-if="applicant.activate_account == 1"
                                            v-model="show_password"
                                            label="Show Password"
                                            true-value="1"
                                            false-value="0"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-text-field dense outlined 
                                    :type="show_password == 1 ? 'text' : 'password'"
                                    label="Password"
                                    append-icon="mdi-refresh"
                                    v-if="applicant.activate_account == 1"
                                    @click:append="generatePassword()"
                                    v-model="temp_password">
                                </v-text-field>
                            </div>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="default" @click="applicant_stepper = 1">Back</v-btn>&nbsp;
                                <v-btn color="primary" @click="saveApplicantDetails()">Continue</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card>
                            <v-list dense>
                                <v-list-item v-for="e in events" :key="e.id">
                                    <v-list-item-content>
                                        {{ e.event_id }}
                                        <div>
                                            <small>{{ e.start_date }} - {{ e.end_date }}</small>
                                        </div>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn fab small @click="selectEvent(e)" :color="attended_events.indexOf(e.id) == -1 ? 'grey lighten-3' : 'primary'">
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="default" @click="applicant_stepper = 2">Back</v-btn>&nbsp;
                            <v-btn color="primary" @click="saveEventVisit()">Finish</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
        </v-dialog>
        <v-dialog width="600" v-model="show_participant_details">
            <v-card>
                <v-card-title class="text-center">
                    {{ event.name }}
                </v-card-title>
                <v-card-subtitle>
                    {{ event.event_id }}
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-tabs
                    v-model="tab"
                >
                    <v-tab >
                        Major
                    </v-tab>
                    <v-tab>
                        Minor
                    </v-tab>
                    <v-tab>
                        Exhibitor
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item key="major">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Company
                                    </th>
                                    <th class="text-left">
                                        Apply
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="p in participants.major"
                                    :key="p.id"
                                    >
                                    <td>{{ p.company_name }}</td>
                                    <td>
                                        <v-btn x-small color="primary" :disabled="p.applied == 1"  @click="sendApplication(p)">
                                            {{ p.applied == 1 ? 'Applied' : 'Apply' }}
                                        </v-btn>
                                    </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-tab-item>
                    <v-tab-item key="minor">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Company
                                    </th>
                                    <th class="text-left">
                                        Apply
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="p in participants.minor"
                                    :key="p.id"
                                    >
                                    <td>{{ p.company_name }}</td>
                                    <td>
                                        <v-btn x-small color="primary" :disabled="p.applied == 1" @click="sendApplication(p)">
                                            {{ p.applied == 1 ? 'Applied' : 'Apply' }}
                                        </v-btn>
                                    </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-tab-item>
                    <v-tab-item key="exhibitor">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Company
                                    </th>
                                    <th class="text-left">
                                        Apply
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="p in participants.exhibitor"
                                    :key="p.id"
                                    >
                                    <td>{{ p.company_name }}</td>
                                    <td>
                                        <v-btn x-small color="primary" :disabled="p.applied == 1" @click="sendApplication(p)">
                                            {{ p.applied == 1 ? 'Applied' : 'Apply' }}
                                        </v-btn>
                                    </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-tab-item>
                </v-tabs-items>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="show_participant_details = false">CLOSE</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    name : 'ApplicantList',
    data() {
        return {
            email : null,
            applicants : [],
            applicant : {},
            events : [],
            event : {},
            applicant_dialog : false,
            applicant_stepper : 1,
            cities : [],
            provinces : [],
            visits : [],
            table_headers : [
                {
                    value : 'id',
                    text : 'Applicant Number',
                    sortable : true
                },
                {
                    value : 'event_id',
                    text : 'Event ID',
                    sortable : true
                },
                {
                    value : 'name',
                    text : 'Applicant',
                    sortable : true
                },
                {
                    value : 'contact_no',
                    text : 'Contact Number',
                },
                {
                    value : 'municipality',
                    text : 'City',
                },
                {
                    value : 'applying_for',
                    text : 'Applying For',
                },
                {
                    value : 'went',
                    text : 'Attended',
                },
                {
                    text : 'Applications',
                }
            ],
            show_participant_details : false,
            participants : [],
            tab : 'major',
            search : null,
            temp_password : null,
            show_password : 0,
            educational_attainments : [
                'No Formal Education',
                'Elementary Level',
                'Elementary Graduate',
                'High School level',
                'High School Graduate (Old Curriculum)',
                'Senior High School Graduate (New Curriculum)',
                'Vocational_course/Short_courses',
                'TESDA Graduate',
                'College Undergrad(1st -2nd yr level)',
                'Associate / 2yr Course Graduate',
                'College Undergrad(3rd -4th yr level)',
                'College_Graduate',
                'Masteral / Doctorate Degree',
            ]
        }
    },
    mounted(){
        if(this.$route.query.email) {
            this.email = this.$route.query.email;
            this.searchApplicant();
        }
        axios({
            url : process.env.VUE_APP_API_URL + '/f2f/address',
            method : 'GET'
        }).then(res => {
            this.cities = res.data.cities.map(d => {
                return d.city_name;
            });
            this.provinces = res.data.provinces.map(d => {
                return d.province_name;
            });
        });
    },
    methods: {
        searchApplicant() {
            if(!this.email) {
                alert("Email is required");
                return;
            }
            axios({
                url : process.env.VUE_APP_API_URL + '/f2f/applicants',
                method : 'GET',
                params : {
                    email : this.email
                },
                headers : {
                    Authorization : 'Bearer ' + this.$store.state.code
                }
            }).then(res => {
                if(!res.data.applicant) {
                    let message = this.email + " is not yet registered in our system.\n";
                    message += "Do you want to add this applicant manually?"
                    if(confirm(message)) {
                        this.applicant_dialog = true;
                        return;
                    }
                }

                if(res.data.visits.length == 0) {
                    let message = this.email + " hasn't visited any future event online.\n"
                    message += "Do you want to set his/her online visit manually?"

                    if(confirm(message)) {
                        this.applicant_dialog = true;
                        this.applicant = res.data.applicant;
                        this.getEvents();
                        this.applicant_stepper = 2;
                    }
                }
                this.visits = res.data.visits.map(d => {
                    d.name = d.firstname + ' ' + d.lastname;
                    d.date_attended = new Date(d.date_attended).toDateString()
                    return d;
                });
            });
        },
        saveEventVisit() {
            axios({
                url : process.env.VUE_APP_API_URL + '/f2f/events/' + this.applicant.id,
                method : 'POST',
                data : {
                    events : this.attended_events
                },
                headers : {
                    Authorization : 'Bearer ' + this.$store.state.code
                }
            }).then(res => {
                this.applicant_dialog = false;

                this.searchApplicant();
            })
        },
        saveApplicantDetails() {
            let applicant = Object.assign({}, this.applicant);
            if(applicant.status != 'Active' && applicant.activate_account == 1) {
                if(!this.temp_password) {
                    alert("Please set a temporary password.");
                    return;
                }
                applicant.password = this.temp_password;
            }

            if(this.applicant.id == undefined) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/f2f/applicant-details',
                    method : 'POST',
                    data : applicant,
                    headers : {
                        Authorization : 'Bearer ' + this.$store.state.code
                    }
                }).then(res => {
                    this.applicant = res.data;

                    this.getEvents();
                    this.applicant_stepper = 3;
                });
            }
            else {
                axios({
                    url : process.env.VUE_APP_API_URL + '/f2f/applicant-details',
                    method : 'PUT',
                    data : applicant,
                    headers : {
                        Authorization : 'Bearer ' + this.$store.state.code
                    }
                }).then(res => {
                    this.applicant = res.data;
                    
                    this.getEvents();
                    this.applicant_stepper = 3;
                })
            }
            
        },
        generatePassword() {
            let random = parseInt(Math.random() * 9999999);

            this.temp_password = random;
        },
        getEvents() {
            axios({
                url : process.env.VUE_APP_API_URL + '/f2f/events/' + this.applicant.id,
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.state.code
                }
            }).then(res => {
                let attended = res.data.visited_events.map(d => {
                    return d.virtual_event_id;
                });

                this.events = res.data.events;
                this.attended_events = attended;

                console.log(this.events);
                console.log(this.attended_events);
            })
        },
        selectEvent(event) {
            let attended = this.attended_events;
            let index = attended.indexOf(event.id);
            if(index >= 0) {
                attended.splice(index, 1);
            }
            else {
                attended.push(event.id);
            }

            this.attended_events = attended;

            this.$forceUpdate();
        },
        getUserByEmail() {
            axios({
                url : process.env.VUE_APP_API_URL + '/f2f/search-email',
                method : 'POST',
                data : {
                    email : this.email
                },
            }).then(res => {
                this.applicant = res.data;
                if(!this.applicant.id) {
                    this.applicant.email = this.email;
                }

                this.applicant_stepper = 2;
            })
        },
        confirmAttendance(applicant) {
            if(confirm('Are you sure this applicant went to the face to face event?')) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/f2f/confirm-attendance/' + applicant.id,
                    method : 'POST',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.state.code
                    }
                }).then(res => {
                    this.searchApplicant();
                }).catch(err => {
                    alert("Something went wrong.\nPlease contact your administrator.");
                })
            }
        },
        getApplications(application) {
            this.event = application;

            axios({
                url : process.env.VUE_APP_API_URL + '/f2f/event-participants/' + application.eid + '/' + application.user_id,
                method : 'GET',
                headers : {
                    Authorization : 'Bearer ' + this.$store.state.code
                }
            }).then(res => {
                let applications = res.data.applications;
                let major = res.data.major.map(d => {
                    d.applied = applications.indexOf(d.id) >= 0;
                    return d; 
                });

                let minor = res.data.minor.map(d => {
                    d.applied = applications.indexOf(d.id) >= 0;
                    return d; 
                });

                let exhibitor = res.data.exhibitor.map(d => {
                    d.applied = applications.indexOf(d.id) >= 0;
                    return d; 
                });
                this.participants = {
                    major : major,
                    minor : minor,
                    exhibitor : exhibitor
                };

                console.log(this.participants);

                this.show_participant_details = true;
            });
        },
        sendApplication(project) {
            ///f2f/send-application
            if(confirm('Are you sure?')) {
                axios({
                    url : process.env.VUE_APP_API_URL + '/f2f/send-application',
                    method : 'POST',
                    data : {
                        user_id : this.event.user_id,
                        project_id : project.id,
                        event_id : this.event.eid
                    },
                    headers : {
                        Authorization : 'Bearer ' + this.$store.state.code
                    }
                }).then(res => {
                    project.applied = 1;
                })
            }
        }
    }
}
</script>