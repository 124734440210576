<template>
    <section class="login-bg">
        <v-row class="mt-5">
            <v-col cols="12" sm="12" md="4"></v-col>
            <v-col cols="12" sm="12" md="4">
                <div class="pa-5">
                    <v-card>
                        <v-card-title>Magnus Job Fair</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-text-field label="Access Code" outlined hide-details v-model="code"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn block color="primary" @click="authenticate()">ENTER</v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="4"></v-col>
        </v-row>
    </section>
</template>
<style>
    .login-bg {
        background-image : url('@/assets/login-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        width : 100%;
        height : 100%;
    }
</style>

<script>
    export default {
        name : 'LoginView',
        data() {
            return {
                code : null,
                error : false
            }
        },
        mounted() {
            console.log(this.$store.state.api_url);
        },
        methods: {
            authenticate() {
                this.axios({
                    url : this.$store.state.api_url + '/f2f/authenticate',
                    method : 'POST',
                    data : {code : this.code}
                }).then(res => {
                    localStorage.setItem('auth_code', this.code);

                    this.$store.commit('setMessage', this.code);
                    this.$store.dispatch('authenticate');
                    this.$router.push({name : 'ApplicantsList'})
                }).catch(err => {
                    console.log(err);
                })
            }
        }
    }
</script>