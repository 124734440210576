import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"login-bg"},[_c(VRow,{staticClass:"mt-5"},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}}),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('div',{staticClass:"pa-5"},[_c(VCard,[_c(VCardTitle,[_vm._v("Magnus Job Fair")]),_c(VDivider),_c(VCardText,[_c(VTextField,{attrs:{"label":"Access Code","outlined":"","hide-details":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.authenticate()}}},[_vm._v("ENTER")])],1)],1)],1)]),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }