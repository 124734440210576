<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            app
            color="black"
            dark
        >
            <div class="logo-text white--text text-center mt-3">
                <h3>MAGNUS JOB FAIR</h3>
                <label for="">{{ user.name }}</label>
            </div>
            <div class="text-center mb-5 mt-5">
                <img src="@/assets/logo.png" alt="" height="80" />
            </div>
            <v-divider></v-divider>
            <v-list dense nav>
                <v-list-item-group>
                    <v-list-item :to="{name : 'Applicants'}">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Applicants</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="logout()">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Sign-Out</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>

            </v-list>
        </v-navigation-drawer>
    
        <v-app-bar app color="primary" class="darken-4" dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    
            <v-toolbar-title>Applicants</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-avatar color="indigo" class="darken-2 pointer" size="40">
            </v-avatar>
        </v-app-bar>
    
        <v-main>
            <router-view></router-view>
        </v-main>
    </div>
</template>
<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
<script>
import axios from 'axios';
export default {
    mounted() {
        axios({
            url : process.env.VUE_APP_API_URL + '/f2f/user-details',
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.state.code
            }
        }).then(res => {
            this.user = res.data;
        })
    },
    data() {
        return {
            drawer: null,
            user : {}
        }
    },
    methods: {
        redirect(link) {
            this.$router.push({name : link});
        },
        logout() {
            localStorage.removeItem('user');
            localStorage.removeItem('authenticated');
            this.$router.push({name : 'Login'})
        }
    }
}
</script>