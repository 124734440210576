import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api_url : 'https://access.magnusjobfair.com/api',
    authenticated : localStorage.getItem('authenticated') || false,
    code : localStorage.getItem('auth_code') || null
  },
  getters: {
  },
  mutations: {
    setCode(state, code) {
      state.code = code;
    }
  },
  actions: {
    logout() {
      this.state.authenticated = false;
      localStorage.removeItem('authenticated');
    },
    authenticate() {
      this.state.authenticated = true;
      localStorage.setItem('authenticated', true);
    }
  },
  modules: {
  }
})
